.btn-serve {
  border-radius: 20px;
  border: 1px solid;
  background: #fff;
  pointer-events: none;
  color: #677294;
  margin-right: 4px;
  margin-bottom: 10px;
}

.book-consulation {
  width: 50%;
  min-height: 50px;
  background: #f26522;
  border-color: #f26522;
  margin-top: 40px;
  display: inline-flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.book-link {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.book-link:hover {
  color: #fff;
}

.price-border {
    border: 4px solid yellow;
}