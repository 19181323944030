	/*
  	Flaticon icon font: Flaticon
  	Creation date: 21/01/2020 18:12
  	*/

@font-face {
  font-family: "Flaticon";
  /* src: url("./Flaticon.eot"); */
  src: url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff");
       /* url("./Flaticon.ttf") format("truetype"); */
       /* url("./Flaticon.svg#Flaticon") format("svg"); */
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    /* src: url("./Flaticon.svg#Flaticon") format("svg"); */
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-phone:before { content: "\f100"; }
.flaticon-chat:before { content: "\f101"; }
.flaticon-pin:before { content: "\f102"; }
.flaticon-sound:before { content: "\f103"; }