@media (max-width: 1400px) {
  .frelencer_banner_area {
    height: 700px; /* 880px */
  }
  .home_bubble .bubble.b_two {
    top: 100px;
  }
}
@media (max-width: 1199px) {
  .about_img {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
  .about_img img {
    max-width: 100%;
    margin-left: 0;
  }
  .blog_post .post_content .blog-meta span {
    font-size: 14px;
  }
  .about_content {
    padding-right: 0px;
  }
  .promo_section .promo_box_one {
    padding: 0px 15px;
  }
  .promo_section .promo_skill {
    padding-left: 15px;
    padding-right: 15px;
  }
  .banner_shap,
  .banner_shap_two {
    background-position: 72%;
  }
  .frelencer_banner_area {
    height: 650px;
  }
  .frelencer_content h4 span {
    font-size: 13px;
    letter-spacing: 4px;
  }
  .frelencer_img {
    width: 450px;
  }
  .f_img_four {
    right: 0px;
  }
  .frelencer_content h2 {
    line-height: 64px;
  }
  .fact_img {
    margin-right: 0;
  }
  .testimonial_slider_info {
    padding-left: 0px;
  }
  .error_text:after {
    background-size: contain;
    bottom: 20px;
  }
  .line-arrow-container{
    width: 160px;
    right: -80px;
  }
}
@media (max-width: 991px) {
  .about_img {
    text-align: center;
  }
  .about_content .theme_btn + .theme_btn {
    margin-left: 6px;
  }
  .contact-area .contact_info {
    padding-left: 0;
  }
  .blog_post {
    margin-bottom: 50px;
  }
  .blog_area .row {
    margin-bottom: -50px;
  }
  .navbar {
    padding: 0px 15px;
  }
  .navbar .login {
    margin-top: 0px;
    margin-left: auto;
    margin-right: 20px;
  }
  .navbar-toggler span {
    background: #fff;
  }
  .menu_two .navbar-toggler span {
    background: #fff;
  }
  .menu_two.navbar .login {
    display: none !important;
  }
  .navbar_fixed .menu_two .navbar-toggler span,
  .menu_two.navbar .navbar-nav .nav-item .nav-link:before {
    background: #f26522;
  }
  .dark_menu .navbar-toggler span {
    background: #040c2c;
  }
  .navbar_fixed .navbar-toggler span,
  .navbar .navbar-nav .nav-item .nav-link:before {
    background: #040c2c;
  }
  .red_color .navbar_fixed .navbar-toggler span {
    background: #ff2a39;
  }
  .navbar .navbar-nav .nav-item {
    margin-left: 0px;
  }
  .navbar-nav {
    background: #fff;
    padding: 20px 20px 15px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    color: #040c2c;
    display: inline-block;
  }
 .navbar .navbar-nav .nav-item .custom-button-form-new{
    color: #040c2c !important;
  }
  .about_area_two .about_item {
    margin-bottom: 30px;
  }
  .about_area_two {
    padding-bottom: 90px;
  }
  .promo_section .promo_box_one {
    padding: 80px 30px 100px;
  }
  .promo_section .promo_video img {
    position: relative;
  }
  .promo_section .promo_skill {
    padding: 90px 30px 80px;
  }
  .team_item {
    margin-bottom: 40px;
  }
  .team_area {
    padding-bottom: 60px;
  }
  .banner_area_two .banner_content h2 {
    font-size: 58px;
  }
  .navbar .navbar-nav .nav-item + .nav-item {
    margin-top: 5px;
  }
  .frelencer_img {
    width: 532px;
    height: 504px;
  }
  .frelencer_banner_area {
    height: auto;
    padding-top: 130px;
  }
  .s_shap {
    display: block;
    left: -240px;
  }
  .frelencer_img {
    margin-top: 30px;
  }
  .service_img {
    text-align: center;
    padding-top: 40px;
  }
  .testimonial_slider_info {
    margin-top: 50px;
  }
  .frelencer_content {
    margin-right: 0;
  }
  .clients_intrigration {
    width: 450px;
    height: 430px;
    margin-top: 60px;
  }
  .get_info {
    margin-bottom: 50px;
  }
  .blog_area_two .blog_post .post_content {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .clients_logo_area_two .row {
    margin-bottom: -30px;
  }
  .custom-button-form{
    color: #040c2c;
    padding: 0px !important;
  }
  .service-child{
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
  .line-arrow-container{
    width: 110px;
    right: -56px;
  }
  .pricing-row-1 .pricing-col-2{
    min-height: 180px;
  }
  .pricing-row-3 .pricing-col-2{
    min-height: 129px;
  }
}

@media (max-width: 767px) {
  .project_count_area .row {
    margin-bottom: -30px;
  }
  .project_count_area .counter_item {
    margin-bottom: 30px;
  }
  .contact-area .contact_info {
    margin-bottom: 50px;
  }
  .about_img img {
    max-width: 100%;
    margin-left: 0px;
  }
  .about_content {
    padding: 0px 0px 40px;
  }
  .navbar-collapse {
    margin-left: -15px;
    margin-right: -15px;
  }
  .navbar-right {
    display: none;
  }
  .banner_content h5 {
    margin-bottom: 10px;
  }
  .banner_content h4 {
    letter-spacing: 0px;
    margin-bottom: 0px;
  }
  .banner_content {
    padding-top: 45px;
  }
  .navbar-collapse {
    max-height: 275px;
    overflow-y: scroll;
  }
  .banner_area_two .banner_content h2 {
    font-size: 53px;
  }
  .navbar .logo_h img {
    max-width: 80%;
  }
  .navbar .login {
    padding: 3px 27px;
  }
  .navbar_fixed .navbar .navbar-toggler {
    height: 70px;
  }
  .navbar-light .navbar-toggler {
    transition: all 0.3s ease-in-out;
  }
  .footer-area-two .footer-content ul li {
    padding: 0px 2px;
  }
  .footer-content p {
    letter-spacing: 2px;
  }
}
@media (max-width: 667px) {
  .error_text:after {
    display: none;
  }
  .error_text {
    margin-top: 0;
  }
  .error_text h1 {
    line-height: 240px;
    padding-left: 0;
  }
  .error_text img {
    top: -42px;
  }
  .back_btn {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .portfolio_menu li {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .portfolio img {
    width: 100%;
  }
  .testimonial_area .testimonial_slider {
    padding: 0px;
  }
  .title_p br {
    display: none;
  }
  .title_h2 {
    font-size: 27px;
    line-height: 40px;
  }
  .banner_content h2 {
    font-size: 38px;
  }
  .about_content .theme_btn {
    margin-bottom: 8px;
    display: block;
    margin-left: 0;
  }
  .about_content .theme_btn + .theme_btn {
    margin-left: 0;
  }
  .work_area,
  .portfolio_area {
    padding: 80px 0px 60px;
  }
  .project_count_area,
  .clients_logo_area,
  .blog_area,
  .contact-area {
    padding: 90px 0px;
  }
  .frelencer_content h4 span {
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 20px;
  }
  .frelencer_content h2 {
    font-size: 31px;
    line-height: 45px;
  }
  .service_img img {
    width: 390px;
  }
  .s_item {
    border-left: 0px;
  }
  .s_item:nth-child(n + 3) {
    border-top: 0px;
  }
  .f_img_seven,
  .f_img_eight,
  .f_img_nine {
    display: none;
  }
  .f_img_two {
    top: 135px;
    left: 10px;
  }
  .f_img_one {
    left: 0px;
  }
  .f_img_three {
    right: 205px;
  }
  .f_img_five {
    left: 85px;
  }
  .f_img_six {
    left: 192px;
  }
  .section_title_two h2 {
    font-size: 30px;
  }
  .blog_area_two .blog_post .post_content {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .clients_intrigration {
    width: 290px;
  }
  .clients_intrigration .c_items:nth-child(1),
  .clients_intrigration .c_items:nth-child(5) {
    left: 0;
  }
  .clients_intrigration .c_items:nth-child(3),
  .clients_intrigration .c_items:nth-child(7) {
    right: 0;
  }
  .footer-area-two .footer-content {
    text-align: center;
  }
  .footer-area-two .text-right {
    text-align: center !important;
    padding-top: 15px;
  }
  .error_text p br {
    display: none;
  }
  .error_text h1 {
    font-size: 180px;
    line-height: 180px;
  }
  .error_text:before {
    background-size: contain;
  }
  .error_text img {
    top: 0;
  }
  .get_item i:before {
    margin-left: 0;
    font-size: 35px;
  }
  .get_item .media-body a,
  .get_item .media-body p {
    font-size: 20px;
  }
  .service-child{
    flex-basis: 100%;
    max-width: 100%;
  }
  .slider-child{
    flex-basis: 100%;
    max-width: 100%;
  }
  .s_shap {
    display: none;
  }
  .frelencer_img{
    display: none;
  }
  .frelencer_content{
    margin-bottom: 30px;
  }
  .mobile-style-hidden{
    display: none;
  }
  .company-img-gap{
    margin: 0px 0px 0px 45px !important;
  }
  .mobile-style-show{
    display: inline-block;
  }
  .company-logo-container img {
    margin-bottom: 12px;
  }
  .skill_content_gap{
    margin-bottom: 15px !important;
  }
  .step-line-container{
    display: none;
  }
  .process-instruction-container{
    flex-direction: column;
  }
  .step-1-container, .step-2-container, .step-3-container, .step-4-container{
    width: 100%;
  }
  .step-mobile-container{
    display: flex;
    margin-bottom: 10px;
  }
  .step-2-container{
    margin: 25px 0px;
  }
  .step-3-container{
    margin-bottom: 25px;
  }
  .profile-wrapper{
    flex-direction: column;
  }
  .profile-container{
    width: 100%;
    margin: 10px 0px;
  }
  .instruction-header-without-text{
    display: none;
  }
  .pricing-col-2, .pricing-col-3{
    width: 50%;
  }
  .pricing-col-1{
    display: none;
  }
  .pricing-col-2{
    border-left: none;
  }
  .pricing-header{
    font-size: 17px;
    line-height: 23px;
  }
  .pricing-desc{
    font-size: 10px;
    line-height: 13px;
  }
  .pricing-amount{
    font-size: 23px;
  }
  .pricing-amount span{
    font-size: 14px;
  }
  .pricing-amount-2{
    font-size: 15px;
    line-height: 22px;
  }
  .pricing-col-2 button, .pricing-col-3 button {
    padding: 4px 16px;
  }
  .loader-gif{
    padding: 0px 23px;
  }
  .loader-gif svg{
    height: 13px;
  }
  .testimonial-item-container{
    flex-direction: column;
    margin: 10px 0px;
  }
  .testimonial-author-img{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .testimonial-author-img img{
    width: 150px;
    height: 150px;
  }
  .testimonial-quotes{
    font-size: 18px;
  }
  .down-arrow-container{
    bottom: -85px;
  }
}
@media (max-width: 450px) {
  .navbar.navbar_fixed .login {
    display: none;
  }
  .service_img img {
    width: 290px;
  }
  .error_text h1 {
    font-size: 140px;
    line-height: 135px;
  }
  .error_text img {
    top: 29px;
  }
  .modal-main{
    min-width:calc(100vw - 16px);
    margin: 8px auto;
    top: 72px;
    left: 2%;
    right: 2%;
  }
  .footer-nav-container{
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
  .question-series-area{
    min-height: 500px;
    max-height: 500px;
    padding: 0rem 0rem;
  }
  .question-series-wrapper{
    padding: 4rem 1rem 1.5rem 1rem;
    height: 410px;
  }
  .question-instruction-wrapper {
    padding: 0rem 1rem 1.5rem 1rem !important;
  }
  .question-title-container {
    font-size: 15px;
    line-height: 20px;
  }
  .question-series-instruction-container{
    font-size: 18px;
    line-height: 22px;
  }
  .question-series-instruction-container-custom{
    margin-top: 8rem;
  }
  .radio-btn-group {
    font-size: 12px;
    line-height: 30px;
  }
  .question-control-btn-group{
    padding: 0rem 1rem;
  }
  .btn-for-next button{
    padding: 10px 30px;
  }
  .project-textArea-container textarea {
    font-size: 11px;
    line-height: 15px;
  }
  .textarea-placeholder{
    font-size: 12px;
    line-height: 28px;
  }
  .textarea-placeholder p{
    margin-bottom: 0.5px;
    font-size: 15px;
  }
  .textarea-placeholder ul{
    padding-inline-start: 20px;
  }
  .li-last-container{
    margin-top: 5px;
  }
  .custom-button-form-padding-hide{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
  }
  .question-1-input-container {
    margin-top: 5px;
  }
  .testimonial-author-img{
    width: auto;
    height: auto;
  }
  /* .book-btn-container{
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  } */
}

@media (max-width: 410px) {
  .modal-title{
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .card-logo-image img{
    width: 95px;
    height: 95px;
  }
}

@media (min-width: 1020px) {
  .modal-card-container{
    flex-direction: row;
  }
  .card-left-container{
    padding: 0px 24px;
  }
  .card-right-container{
    padding: 0px 24px;
  }
  .modal-title{
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  .card-captions{
    font-size: 20px;
    line-height: 28px;
  }
}
